<template>
  <table>
    <thead>
      <tr>
        <th v-for="(item, index) in listWeek" :key="index">
          <span>{{ item.day }}</span>
          <strong>{{ item.date }}</strong>
        </th>
      </tr>
    </thead>
    <tbody v-html="tableCalendar"></tbody>
  </table>
</template>

<script>
export default {
  name: "CalendarWeek",
  props: {
    listWeek: {
      type: Array,
      default: () => {
        return [];
      }
    },
    tableCalendar: {
      type: String,
      default: ""
    }
  }
};
</script>
<style>
.tg_table_booking_system table thead tr th {
  width: 12%;
}
</style>